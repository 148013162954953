import React, { Component }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Valuation from "./valuation";
import RenovationProjects from "./reno-projects";
import StatementLink from "./statement-link";
import PossiblesButtons from './possibles-buttons';
import './chat-window.css';

class ChatWindow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen : false,
      isWorking: false
    };

    this.responseArea = React.createRef();
    this.messages = React.createRef();
    this.hiddenDiv = React.createRef();
    this.progressIndicator = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleWindow = this.toggleWindow.bind(this);
    this.saveResponse = this.saveResponse.bind(this);
  }

  isOpen() {
    return this.state.isOpen;
  }

  toggleWindow() {
    const openIt = !this.state.isOpen;
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
    if (openIt === true) {
      window.parent?.postMessage({
        type: 'eppraisal',
        class: 'open_greeter',
        datetime: new Date()
      }, '*');
      if (window.gtag !== undefined) {
        window.gtag('event', 'houses_clicked', {
          category: 'User'
        });
      }
    }
    else {
      window.setTimeout(() => {
        window.parent?.postMessage({
          type: 'eppraisal',
          class: 'closed_greeter',
          datetime: new Date()
        }, '*');
      }, 1000);
    }
  }

  async saveResponse(actualResponse) {
    let expression = null;
    let formattedResponse;
    const messageType = this.props.conversation.messages[this.props.currentMessageId].type;

    switch (messageType) {
      case 'address_1':
        formattedResponse = { address1: actualResponse };
        break;
      case 'address_2':
        formattedResponse = { address2: actualResponse };
        break;
      case 'full_name':
        const firstSpace = actualResponse.indexOf(' ') ?? null;
        const firstName = actualResponse.substring(0, firstSpace);
        const lastName = firstSpace !== null ? actualResponse.substring(firstSpace + 1) : null;
        formattedResponse = { firstName, lastName: lastName };
        break;
      case 'email':
        expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (actualResponse.toLowerCase().match(expression) !== null) {
          formattedResponse = { email: actualResponse }; 
        }        
        break;
      case 'phone':
        expression = /^1?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const matches = actualResponse.match(expression);
        if (matches !== null && matches.length === 4) {
          formattedResponse = { phone: `${matches[1]}${matches[2]}${matches[3]}` };
        }
        break;
      default:
        formattedResponse = actualResponse;
        break;
    }
    let stillWaiting = await this.props.saveResponse(formattedResponse);
    if (messageType === 'projects' || messageType === 'valuation') {
      setTimeout(() => {
        this.setState({ isWorking: stillWaiting });  
      }, 500);
    }
    else {
      this.setState({ isWorking: stillWaiting });
    }
    
    if (window.gtag !== undefined) {
      window.gtag('event', 'response_entered', {
        category: 'User'
      });
    }
  }

  async handleSubmit(event) {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    const response = event.response ?? this.responseArea.current.value;
    this.responseArea.current.value = '';
    if (response === '') {
      return;
    }

    this.setState({ isWorking: true });
    this.progressIndicator.current?.scrollIntoView({ behavior: "smooth" });
    await this.saveResponse(response);
  }

  handleKeyPress(event) {
    event.target.parentNode.dataset.replicatedValue = event.target.value;

    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      event.target.form[1].click();
      event.target.parentNode.dataset.replicatedValue = event.target.value = '';
    }
  }
  
  getMessage(messageId) {
    const currentMessage = this.props.conversation.messages[messageId];
    if (messageId === this.props.currentMessageId && this.state.isWorking === true &&
      (currentMessage.type !== 'valuation' && currentMessage.type !== 'projects')) {
      return null;
    }
    switch (currentMessage.type) {
      case 'valuation':
        return (<Valuation key={`message-${messageId}`} address1={this.props.responses.address1 ?? null} style={this.props.settings?.style}
          address2={this.props.responses.address2 ?? null} appKey={this.props.appKey} captchaToken={this.props.captchaToken}
          valuation={this.props.responses.valuation ?? null} executeRecaptcha={this.props.executeRecaptcha} saveResponse={this.saveResponse} />);
      case 'projects':
        return (<RenovationProjects key={`message-${messageId}`} address1={this.props.responses.address1 ?? null}
          address2={this.props.responses.address2} appKey={this.props.appKey} captchaToken={this.props.captchaToken}
           projects={this.props.responses.projects ?? null} executeRecaptcha={this.props.executeRecaptcha} saveResponse={this.saveResponse} />);
      case 'links_valuation':
      case 'links_valuation_mortgage':
      case 'links_valuation_projects':
      case 'links_valuation_projects_mortgage':
      case 'links_projects':
      case 'links_projects_mortgage':
        return (<StatementLink key={`message-${messageId}`} appKey={this.props.appKey} messageType={currentMessage.type}
          responses={this.props.responses} interviewee={this.props.interviewee} settings={this.props.settings}
          content={currentMessage.content} saveResponse={this.props.saveResponse} />);
      default:
        return (<div key={`message-${messageId}`} className='message-container'>
          <div className='message'>{currentMessage.content}</div></div>);
    }
  }

  getResponse(messageId) {
    const currentMessage = this.props.conversation.messages[messageId];
    if (messageId === this.props.currentMessageId && this.state.isWorking === true &&
      (currentMessage.type !== 'valuation' && currentMessage.type !== 'projects')) {
      return null;
    }
    const content = currentMessage.content !== null && currentMessage.responses.actual !== null ? <div className='message'>{currentMessage.responses.actual.content}</div> : null;
    if (currentMessage.responses.actual === null && currentMessage.responses.possibles !== null) {
      return (
        <PossiblesButtons key='possible-buttons' responses={currentMessage.responses} responseSelected={this.handleSubmit} />
      );
    }

    return (
      <div key={`response-${messageId}`} className='response-container'>{content}</div>
    );
  }

  componentDidUpdate() {
    this.hiddenDiv.current?.scrollIntoView();
  }

  render() {    
    if (this.props.conversation === null) {
      return null;
    }
    
    if (this.state.isOpen === false) {
      return (
        <section className="chatbox chatbox-closed" onClick={this.toggleWindow}>
          <main className="main">
            Get your estimated home value
          </main>
        </section>
      );
    }

    let currentMessageId = this.props.conversation.firstMessageId;
    let messages = [];
    let isWaitingForResponse = false;

    while (currentMessageId !== null) {
      isWaitingForResponse = this.props.conversation.messages[currentMessageId].responses?.possibles !== null;
      const messageType = this.props.conversation.messages[currentMessageId].type;
      const message = this.getMessage(currentMessageId);
      const response = this.getResponse(currentMessageId);
      if (message !== null) {
        messages.push(message);
      }
      if (response !== null) {
        messages.push(response);  
      }
      if (messageType.indexOf('statement') >= 0 || messageType.indexOf('_link') >= 0) {
        currentMessageId = this.props.conversation.messages[currentMessageId].nextMessageId;
      }
      else {
        currentMessageId = this.props.conversation.messages[currentMessageId].responses.actual?.nextMessageId ?? null;
      }
    }
    messages.push(<div key='message-hidden' ref={this.hiddenDiv} />);

    const textareaPlaceholder = isWaitingForResponse ? 'Select one of the options above' : 'Type your message here...';
    return (
      <section className='chatbox chatbox-opened'>
        <header>
            <aside className='titleContainer'>
              <span>powered by Eppraisal</span>
            </aside>
        </header>
        <main id='main' className={`${this.state.isWorking === true ? 'main is-working' : 'main'}`}>
          {messages}
          <div ref={this.progressIndicator} className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
        </main>
        <footer>
          <div className="grow-wrap">
            <textarea disabled={isWaitingForResponse === true} ref={this.responseArea} placeholder={textareaPlaceholder} form='messageForm' onKeyDown={this.handleKeyPress} autoFocus></textarea>
          </div>
          <form id='messageForm' onSubmit={this.handleSubmit}>
            <button type='submit' className='send-button' disabled={this.state.isWorking === true || isWaitingForResponse === true}>
              <FontAwesomeIcon icon={faPaperPlane} size='2xl' />
            </button>
          </form>
          <div className='reCaptchaBranding'>
            This site is protected by reCAPTCHA and the Google&nbsp;
            <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
            <a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
          </div>
        </footer>
      </section>
    );
  }
}

export default ChatWindow;