import React, { Component }  from 'react';
import './reno-projects.css';

export default class RenovationProjects extends Component {

  constructor(props) {
    super(props);

    this.state = {
      attempts: 0,
      isWorking: false,
      isComplete: false,
      projects: null
    };
    this.fetchProjects = this.fetchProjects.bind(this);
    this.retryCatpcha = this.retryCatpcha.bind(this);
  }

  fetchProjects() {
    this.setState(state => ({ isWorking: true, attempts: state.attempts + 1 }));
    let body = {
      address1: this.props.address1,
      address2: this.props.address2,
      captchaToken: this.props.captchaToken
    };

    fetch(`${process.env.REACT_APP_API}/projects`, {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${window.btoa(`${this.props.appKey}:`)}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then((result) => {
      switch (result.status) {
        case 200:
          return result.json();
        case 204:
            return null;
        case 405:
          if (this.state.attempts < 3) {
            this.props.executeRecaptcha();
            setTimeout(() => {
              this.retryCatpcha();
            }, 5000);
          }
          break;
        default:
          break;
      }
    })
    .then((result) => {
      if (result === undefined) {
        this.setState({ isWorking: false });
        return;
      }
      let projects = null;
      let jsonProjects = null;
      if (result !== null) {
        projects = { recommended: result.recommended_projects };
        projects.ctaURL = result.cta_url;
        jsonProjects = JSON.stringify(projects);
      }
      this.setState({ isWorking: false, isComplete: true, projects: jsonProjects });
      this.props.saveResponse({ projects: jsonProjects });
      if (window.gtag !== undefined) {
        window.gtag('event', 'page_view', {
          page_location: window.location + '/projects',
          page_title: 'projects'
        });
      }
    });
  }

  retryCatpcha() {
    if (this.state.projects === null && this.props.captchaToken === null &&
      this.state.isComplete === false && this.state.attempts < 3) {
      this.props.executeRecaptcha();
    }
  }

  componentDidMount() {
    if (this.props.style?.indexOf('greeter') < 0) {
      window.parent?.postMessage({
        type: 'eppraisal',
        class: `${this.props.style} projects`,
        datetime: new Date()
      }, '*');
    }
    if (this.props.address1 === null || this.props.address2 === null || this.props.captchaToken === null ||
      this.state.isWorking === true || this.state.attempts >= 3 || this.props.projects !== null) {
      return;
    }

    this.fetchProjects();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.address1 === null || this.props.address2 === null || this.props.captchaToken === null ||
      this.state.isWorking === true || this.state.attempts >= 3 || this.state.projects !== null
      || this.props.captchaToken === prevProps.captchaToken) {
      return;
    }

    this.fetchProjects();
  }

  render() {
    let result = null;
    let projects = this.props.projects ?? this.state.projects;
    if (projects !== null) {
      projects = JSON.parse(projects);
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      result = (<div>
        <div>{projects.recommended.map((project, index) => (
          <table key={`project-${index}`}>
            <thead><tr><th colSpan={2}>{project.project}</th></tr></thead>
            <tbody>
              <tr>
                <td>Est Cost</td>
                <td>{formatter.format(project.cost_estimate)}</td>
              </tr>
              <tr>
                <td>Range</td>
                <td>{formatter.format(project.cost_estimate_low)} to {formatter.format(project.cost_estimate_high)}</td>
              </tr>
              <tr>
                <td>ROI</td>
                <td>{formatter.format(project.roi)} ({Math.round(project.roi/project.cost_estimate*100)}%)</td>
              </tr>
            </tbody>
          </table>
        ))}</div>
      </div>);
    }
    else if (this.state.isComplete === true && this.state.projects === null) {
      result = (<div><span>Unfortunately, we can't find your property in our database.</span></div>); 
    }

    return (
      <div className={this.state.isComplete === false ? 'message-container reno-projects is-working' : 'message-container reno-projects'}>
        <div className='header'>
        <img src={require('./images/realm-logo.png')} alt="Realm" />
        <h3>Realm Remodel Value</h3>
        </div>
        {result}
        <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
      </div>
    );  
  }
}